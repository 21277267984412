const bootstrap = require("bootstrap");

/**
 * init proces
 */
document.addEventListener("DOMContentLoaded", function () {
  // list view cookie
  if (getCookie("num_listview") === "") {
    setCookie("num_listview", 3, 3650);
  }
  // list view cookie

  // product - add to cart button activate
  if (jQuery("form .add-to-cart").length) {
    setTimeout(function () {
      jQuery("form .add-to-cart").removeClass("disabled").removeClass("loading_data").attr("disabled", false);
    }, 2000);
  }
  // product - add to cart button activate

  // cookie
  WHCheckCookies();
  // cookie
});
/**
 * init proces
 */

/**
 * listener click for ajax elements
 */
document.addEventListener("click", function (e) {
  // hide modal - product added to cart
  if (e.target && e.target.dataset.bsDismiss == "modal") {
    document.getElementById("blockcart-modal").remove();
  }
  // hide modal - product added to cart
});
/**
 * listener click for ajax elements
 */

/**
 * functions
 */
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * cookie
 */

function WHCheckCookies() {
  if (getCookie("gdpr_a") != "T") {
    var html_code =
      '<div id="cookies-message">Strona korzysta z plików cookies w celu realizacji usług i zgodnie z <a href="https://pierwszakomunia.pl/info/polityka-prywatnosci" target="_blank">Polityką prywatności</a>. Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.  <a href="javascript:WHCloseCookiesWindow();" id="accept-cookies-checkbox" name="accept-cookies" >Zgadzam się <i class="fas fa-window-close"></i></a></div>';
    jQuery("#cookies-message-container").html(html_code);
  }
}
window.WHCloseCookiesWindow = function () {
  setCookie("gdpr_a", "T", 1000);
  jQuery("#cookies-message-container").html("");
};

/**
 * cookie
 */

/**
 * change product list view
 */
document.querySelectorAll(".change-list-view").forEach((i) => {
  i.addEventListener("click", function (e) {
    if (i.dataset.to != "") {
      setCookie("num_listview", i.dataset.to, 3650);
      window.location.reload(true);
    }
    e.preventDefault();
    return false;
  });
});
/**
 * change product list view
 */

/**
 * lazyload images
 */
import yall from "yall-js";
document.addEventListener("DOMContentLoaded", function () {
  yall({
    observeChanges: true,
  });
});
/**
 * lazyload images
 */

/**
 * dropdomn menu - ex. sort in product list
 */
// Get all the dropdown from document
document.querySelectorAll(".dropdown-toggle").forEach(dropDownFunc);
// Dropdown Open and Close function
function dropDownFunc(dropDown) {
  // console.log(dropDown.classList.contains('click-dropdown'));

  if (dropDown.classList.contains("click-dropdown") === true) {
    dropDown.addEventListener("click", function (e) {
      e.preventDefault();

      if (this.nextElementSibling.classList.contains("dropdown-active") === true) {
        // Close the clicked dropdown
        this.parentElement.classList.remove("dropdown-open");
        this.nextElementSibling.classList.remove("dropdown-active");
      } else {
        // Close the opend dropdown
        closeDropdown();

        // add the open and active class(Opening the DropDown)
        this.parentElement.classList.add("dropdown-open");
        this.nextElementSibling.classList.add("dropdown-active");
      }
    });
  }

  if (dropDown.classList.contains("hover-dropdown") === true) {
    dropDown.onmouseover = dropDown.onmouseout = dropdownHover;

    function dropdownHover(e) {
      if (e.type == "mouseover") {
        // Close the opend dropdown
        closeDropdown();

        // add the open and active class(Opening the DropDown)
        this.parentElement.classList.add("dropdown-open");
        this.nextElementSibling.classList.add("dropdown-active");
      }

      // if(e.type == 'mouseout'){
      //     // close the dropdown after user leave the list
      //     e.target.nextElementSibling.onmouseleave = closeDropdown;
      // }
    }
  }
}
// Listen to the doc click
window.addEventListener("click", function (e) {
  // Close the menu if click happen outside menu
  if (e.target.closest(".dropdown-container") === null) {
    // Close the opend dropdown
    closeDropdown();
  }
});
// Close the openend Dropdowns
function closeDropdown() {
  // remove the open and active class from other opened Dropdown (Closing the opend DropDown)
  document.querySelectorAll(".dropdown-container").forEach(function (container) {
    container.classList.remove("dropdown-open");
  });

  document.querySelectorAll(".dropdown-menu").forEach(function (menu) {
    menu.classList.remove("dropdown-active");
  });
}
// // close the dropdown on mouse out from the dropdown list
// document.querySelectorAll('.dropdown-menu').forEach(function (dropDownList) {
//     // close the dropdown after user leave the list
//     dropDownList.onmouseleave = closeDropdown;
// });
/**
 * dropdomn menu - ex. sort in product list
 */

/**
 * product image thumb move / slide
 */
function scrollProductThumbs(direction) {
  var currentPosition = document.querySelector("#product-images-thumbs").scrollTop;
  var move = document.querySelector(".product-images-wrapper").offsetHeight / 2.5;
  if (direction === "top") {
    document.querySelector(".product-images").scrollTo({ top: currentPosition - move, left: 0, behavior: "smooth" });
    // document.querySelector("#product-images-thumbs").style.top = (currentPosition - move) + "px";
  } else {
    document.querySelector(".product-images").scrollTo({ top: currentPosition + move, left: 0, behavior: "smooth" });
    // document.querySelector("#product-images-thumbs").style.top = (currentPosition + move) + "px";
  }
}
if (document.getElementById("move-product-thumbs-arrow-top")) {
  document.getElementById("move-product-thumbs-arrow-top").addEventListener("click", (e) => {
    scrollProductThumbs("top");
  });
}
if (document.getElementById("move-product-thumbs-arrow-down")) {
  document.getElementById("move-product-thumbs-arrow-down").addEventListener("click", (e) => {
    scrollProductThumbs("down");
  });
}
/**
 * product image thumb move / slide
 */

/**
 * product images thumbs event click - open thumb in main container
 */
if (document.querySelectorAll("#product-images-thumbs img")) {
  document.querySelectorAll("#product-images-thumbs img").forEach((i) => {
    i.addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector("#product-cover-main img").src = i.dataset.zoom;
      return false;
    });
  });
}
/**
 * product images thumbs event click - open thumb in main container
 */

/**
 * product full size gallery
 */
function openFullSizeGallery(firstImageSrc = null) {
  if (firstImageSrc) {
    document.querySelector("#full-size-galllery-first-image img").src = firstImageSrc;
    document.getElementById("full-size-galllery-first-image").style.display = "block";
  }
  document.getElementById("full-size-galllery-title").innerText = document.getElementById("product-header-name").innerText;
  document.querySelector("body").classList.add("full-size-galllery-open");
  document.getElementById("full-size-galllery-container").classList.add("show");
}
if (document.querySelector("#product-cover-main a")) {
  document.querySelector("#product-cover-main a").addEventListener("click", function (e) {
    e.preventDefault();
    openFullSizeGallery();
    return false;
  });
}
if (document.querySelectorAll("#product-images-second img")) {
  document.querySelectorAll("#product-images-second img").forEach((i) => {
    i.addEventListener("click", function (e) {
      e.preventDefault();
      openFullSizeGallery(i.dataset.xxxlarge);
      return false;
    });
  });
}
if (document.querySelectorAll("#product-images-mobile img")) {
  document.querySelectorAll("#product-images-mobile img").forEach((i) => {
    i.addEventListener("click", function (e) {
      e.preventDefault();
      openFullSizeGallery(i.dataset.xxxlarge);
      return false;
    });
  });
}
if (document.querySelector(".product-content .product-cover img")) {
  document.querySelector(".product-content .product-cover img").addEventListener("click", function (e) {
    e.preventDefault();
    openFullSizeGallery();
    return false;
  });
}
if (document.getElementById("full-size-galllery-close-btn")) {
  document.getElementById("full-size-galllery-close-btn").addEventListener("click", function (e) {
    document.querySelector("body").classList.remove("full-size-galllery-open");
    document.getElementById("full-size-galllery-container").classList.remove("show");
    document.querySelector("#full-size-galllery-first-image img").src = "";
    document.getElementById("full-size-galllery-first-image").style.display = "none";
  });
}
/**
 * product full size gallery
 */

/**
 * product details - cover image near to product description
 */
// if (document.querySelector("#main .product-content .product-cover")) {
//   document.querySelector("#main .product-content .product-cover").style.height = document.querySelector("#main .product-content .product-information").clientHeight * 0.8 + "px";
// }
/**
 * product details - cover image near to product description
 */

/**
 * adding do car in product
 */
jQuery("form .add-to-cart").on("click", function (e) {
  if (!validateAddToCartForm()) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  // // info about adding product to cart - add info
  jQuery("form .add-to-cart").addClass("disabled").addClass("adding_to_cart");
});

// info about adding product to cart - remove info
prestashop.on("updateCart", function () {
  console.log("updateCart");
  if (jQuery("form .add-to-cart.adding_to_cart").length > 0) {
    setTimeout(function () {
      jQuery("form .add-to-cart.adding_to_cart").removeClass("disabled").removeClass("adding_to_cart");
    }, 3000);
  }
});

var validFunctionStr =
  "<script>function validateAddToCartForm2() {	  if(jQuery331('#ppbs_widget .unit-entry select').length > 0) {        var validationPass = true;                jQuery('#ppbs_widget .unit-entry select').each(function(){            jQuery(this).removeClass('is-invalid').removeClass('is-valid');            if(jQuery(this).val() === 'empty') {                validationPass = false;                jQuery(this).addClass('is-invalid');            } else {                jQuery(this).addClass('is-valid');            }        });        return validationPass;    }    return true;}jQuery(function(){jQuery331('form .add-to-cart').on('click', function(e){	if(!validateAddToCartForm2()) {		e.preventDefault();		e.stopPropagation();		return false;	} jQuery('form .add-to-cart').prop('disabled', true).addClass('disabled').addClass('adding_to_cart'); });});</script>";

jQuery(".product-variants select").on("change", function () {
  jQuery("form .add-to-cart").addClass("disabled").addClass("loading_data").attr("disabled", true);
});

prestashop.on("updatedProduct", function (event) {
  jQuery("form .add-to-cart").addClass("disabled").addClass("loading_data").attr("disabled", true);
  jQuery("form .product-unavailable-wrapper").addClass("loading_data");

  setTimeout(function () {
    var numFunctionName = "num" + Math.floor(Math.random() * 1000001);
    jQuery("#num_js_wrapper").html(" ");
    jQuery("#num_js_wrapper").html(validFunctionStr.replaceAll("validateAddToCartForm2", numFunctionName));

    if (parseInt(event.num_attribute_quantity) > 0) {
      jQuery("form .add-to-cart").removeClass("disabled").removeClass("loading_data").attr("disabled", false);
      if (!document.querySelector("#add-to-cart-or-refresh .add-to-cart")) {
        window.location.reload(true);
      }
    } else {
      console.log("brak produktu");
      jQuery("form .add-to-cart").removeClass("disabled").attr("disabled", false);
      window.location.reload(true);
    }
  }, 3500);
});

// add product - triggered after add to cart button is click - check all required is set - start
function validateAddToCartForm() {
  if (jQuery("#ppbs_widget .unit-entry select").length > 0) {
    var validationPass = true;

    // check selected value is not 'empty'
    jQuery("#ppbs_widget .unit-entry select").each(function () {
      jQuery(this).removeClass("is-invalid").removeClass("is-valid");
      if (jQuery(this).val() === "empty") {
        validationPass = false;
        jQuery(this).addClass("is-invalid");
      } else {
        jQuery(this).addClass("is-valid");
      }
    });
    return validationPass;
  }

  if (jQuery("#ppbs_widget .unit-entry .personalize-input").length > 0) {
    var validationPass = true;

    // check selected value is not 'empty'
    jQuery("#ppbs_widget .unit-entry .personalize-input").each(function () {
      jQuery(this).removeClass("is-invalid").removeClass("is-valid");
      if (jQuery(this).val() === "") {
        validationPass = false;
        jQuery(this).addClass("is-invalid");
      } else {
        jQuery(this).addClass("is-valid");
      }
    });
    return validationPass;
  }

  // var customImagesRequired = true;
  jQuery(".product-costomizations .custom-image-required").each(function (e) {
    if (jQuery(this).find("img").length === 0) {
      // customImagesRequired = false;
      return false;
    }
  });
  // console.log(customImagesRequired);

  return true;
}

/**
 * adding do car in product
 */

/**
 * cart - plus / minus quantity
 */
function incrementValue(e) {
  e.preventDefault();
  var fieldName = jQuery(e.target).data("field");
  var parent = jQuery(e.target).closest("div");
  var currentVal = parseInt(parent.find("input[name=" + fieldName + "]").val(), 10);

  if (!isNaN(currentVal)) {
    parent.find("input[name=" + fieldName + "]").val(currentVal + 1);
  } else {
    parent.find("input[name=" + fieldName + "]").val(1);
  }
}

function decrementValue(e) {
  e.preventDefault();
  var fieldName = jQuery(e.target).data("field");
  var parent = jQuery(e.target).closest("div");
  var currentVal = parseInt(parent.find("input[name=" + fieldName + "]").val(), 10);

  if (!isNaN(currentVal) && currentVal > 1) {
    parent.find("input[name=" + fieldName + "]").val(currentVal - 1);
  } else {
    parent.find("input[name=" + fieldName + "]").val(1);
  }
}

jQuery(".qty-wrapper").on("click", ".button-plus", function (e) {
  incrementValue(e);
});

jQuery(".qty-wrapper").on("click", ".button-minus", function (e) {
  decrementValue(e);
});
/**
 * cart - plus / minus quantity
 */

/**
 * product - countdown discount
 */
if ($(".product-actions .discount-to-date").length > 0) {
  var dataDate = $(".discount-to-date .discount-to-date-countdown").data("discountto");
  var dateTmp = dataDate.split(" ");
  var onlyDate = dateTmp[0].split("-");
  var onlyTime = dateTmp[1].split(":");

  //var countDownDate = new Date(dataDate).getTime();
  var countDownDate = new Date(onlyDate[0], parseInt(onlyDate[1]) - 1, onlyDate[2], onlyTime[0], onlyTime[1], onlyTime[2]).getTime();
  var x = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    var timeTo = days + " dni " + hours + " godz. " + minutes + " min " + seconds + " sek. ";
    $(".product-actions .discount-to-date .discount-to-date-countdown").text(timeTo);

    if (distance < 0) {
      clearInterval(x);
      $(".product-actions .discount-to-date").hide();
    }
  }, 1000);
}
/**
 * product - countdown discount
 */

/**
 * product - countdown discount - MODULE
 */
if ($(".mod-num-hotdeal .discount-to-date").length > 0) {
  $(".mod-num-hotdeal .hotdeal-product-wrapper").each(function (index) {
    let dataDate = $(this).find(".discount-to-date .discount-to-date-countdown").data("discountto");
    let dateTmp = dataDate.split(" ");
    let onlyDate = dateTmp[0].split("-");
    let onlyTime = dateTmp[1].split(":");

    //let countDownDate = new Date(dataDate).getTime();
    let countDownDate = new Date(onlyDate[0], parseInt(onlyDate[1]) - 1, onlyDate[2], onlyTime[0], onlyTime[1], onlyTime[2]).getTime();
    let x = setInterval(() => {
      let now = new Date().getTime();
      let distance = countDownDate - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      $(this).find(".countdown-value.days .value").text(days);
      $(this).find(".countdown-value.hours .value").text(hours);
      $(this).find(".countdown-value.minutes .value").text(minutes);
      $(this).find(".countdown-value.seconds .value").text(seconds);

      if (distance < 0) {
        clearInterval(x);
        $(this).hide();
      }
    }, 1000);
  });

  // var dataDate = $('.mod-num-hotdeal .discount-to-date .discount-to-date-countdown').data('discountto');
  // var dateTmp = dataDate.split(" ");
  // var onlyDate = dateTmp[0].split("-");
  // var onlyTime = dateTmp[1].split(":");

  // //var countDownDate = new Date(dataDate).getTime();
  // var countDownDate = new Date(onlyDate[0], parseInt(onlyDate[1]) - 1, onlyDate[2], onlyTime[0], onlyTime[1], onlyTime[2]).getTime();
  // var x = setInterval(function () {
  //     var now = new Date().getTime();
  //     var distance = countDownDate - now;

  //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
  //     var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
  //     var seconds = Math.floor(distance % (1000 * 60) / 1000);

  //     $('.mod-num-hotdeal .countdown-value.days .value').text(days);
  //     $('.mod-num-hotdeal .countdown-value.hours .value').text(hours);
  //     $('.mod-num-hotdeal .countdown-value.minutes .value').text(minutes);
  //     $('.mod-num-hotdeal .countdown-value.seconds .value').text(seconds);

  //     if (distance < 0) {
  //         clearInterval(x);
  //         $('.mod-num-hotdeal').hide();
  //     }
  // }, 1000);
}
/**
 * product - countdown discount - MODULE
 */

/**
 * home slider - move heading effect
 */
const homeSlider = document.querySelector(".homeslider-container");
const homeSliderHeadingEffectBg = document.querySelector(".homeslider-container .heading-effect .heading-effect-bg");
if (homeSlider && homeSliderHeadingEffectBg) {
  homeSlider.addEventListener("mousemove", (event) => {
    homeSliderHeadingEffectBg.style.top = `${event.clientY / 30}px`;
    homeSliderHeadingEffectBg.style.left = `${event.clientX / 30}px`;
  });
  homeSlider.addEventListener("mouseout", (event) => {
    homeSliderHeadingEffectBg.style.removeProperty("top");
    homeSliderHeadingEffectBg.style.removeProperty("left");
  });
}
/**
 * home slider - move heading effect
 */

/**
 * homecats - move heading effect
 */
const homeCatsF = document.querySelector(".num_homecats .num_homecats_f");
const homeCatsFHeadingEffectBg = document.querySelector(".num_homecats .num_homecats_f .heading-effect .heading-effect-bg");
if (homeCatsF && homeCatsFHeadingEffectBg) {
  homeCatsF.addEventListener("mousemove", (event) => {
    homeCatsFHeadingEffectBg.style.top = `${event.clientY / 30}px`;
    homeCatsFHeadingEffectBg.style.left = `${event.clientX / 30}px`;
  });
  homeCatsF.addEventListener("mouseout", (event) => {
    homeCatsFHeadingEffectBg.style.removeProperty("top");
    homeCatsFHeadingEffectBg.style.removeProperty("left");
  });
}

const homeCatsM = document.querySelector(".num_homecats .num_homecats_m");
const homeCatsMHeadingEffectBg = document.querySelector(".num_homecats .num_homecats_m .heading-effect .heading-effect-bg");
if (homeCatsM && homeCatsMHeadingEffectBg) {
  homeCatsM.addEventListener("mousemove", (event) => {
    homeCatsMHeadingEffectBg.style.top = `${event.clientY / 30}px`;
    homeCatsMHeadingEffectBg.style.left = `${event.clientX / 30}px`;
  });
  homeCatsM.addEventListener("mouseout", (event) => {
    homeCatsMHeadingEffectBg.style.removeProperty("top");
    homeCatsMHeadingEffectBg.style.removeProperty("left");
  });
}

const categoryListingTitle = document.querySelector(".category-top");
const categoryListingTitleHeadingEffectBg = document.querySelector(".category-top .heading-effect .heading-effect-bg");
if (categoryListingTitle && categoryListingTitleHeadingEffectBg) {
  categoryListingTitle.addEventListener("mousemove", (event) => {
    categoryListingTitleHeadingEffectBg.style.top = `${event.clientY / 30}px`;
    categoryListingTitleHeadingEffectBg.style.left = `${event.clientX / 30}px`;
  });
  categoryListingTitle.addEventListener("mouseout", (event) => {
    categoryListingTitleHeadingEffectBg.style.removeProperty("top");
    categoryListingTitleHeadingEffectBg.style.removeProperty("left");
  });
}

// const homecatsMProdWrapper = document.querySelector('.num_homecats_f .num_homecats_prod_wrapper');
// document.querySelector('.num_homecats_f .num_homecats_cat_wrapper').style.minHeight = homecatsMProdWrapper.offsetHeight;

/**
 * homecats- move heading effect
 */

/**
 * back to top button
 */
document.querySelectorAll(".back-to-top").forEach((item) => {
  item.addEventListener("click", function (e) {
    e.preventDefault();
    document.getElementById("header").scrollIntoView({ behavior: "smooth" });
    return false;
  });
});
/**
 * back to top button
 */
